<template>
  <div class="my-qas app-my-wrap">
    <div class="app-my-title">
      お問い合わせ
    </div>
    <div class="app-my-content">
      <el-form ref="QaForm" :model="form" class="app-form" :rules="rules">
        <div class="my-qas-table-text">
          <div>問い合わせの送信が成功しました。</div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import { Notification } from 'element-ui';
export default {
  data() {
    return {
      form: {
        type: '0',
        title: '',
        content: '',
      },
    };
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.my-qas {
  .app-form {
    .my-qas-table-text {
      div {
        line-height: 30px;
      }
      .my-qas-indent {
        margin-left: 20px;
      }
    }
    .my-qas-button {
      background-color: #F1DCDB;
      padding: 15px;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 18px;
      cursor: pointer;
    }
  }
}
</style>
